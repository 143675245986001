import moment from "moment";
import Lottie from "react-lottie";
import Badge from "../components/Badge";
import ApiServices from "../api/services";
import { useNavigate } from "react-router";
import ReactPaginate from "react-paginate";
import Spinner from "../components/Spinner";
import Skeleton from "react-loading-skeleton";
import { images } from "../assets/styles/Images";
import { useMediaQuery } from "react-responsive";
import { employeeMediaUrl } from "../utils/config";
import { Menu, Transition } from "@headlessui/react";
import { useLoggedContext } from "../context/provider";
import animationData from "../assets/lotties/empty-folder";
import { useSortableData } from "../hooks/useSortableData";
import NotifyUserModal from "../components/NotifyUserModal";
import React, { Fragment, useEffect, useState } from "react";
import ConfirmationModal from "../components/ConfirmationModal";
import InviteUsersModal from "../components/addEmployee/InviteUsersModal";
import AddManuallyModal from "../components/addEmployee/AddManuallyModal";
import { ArrowPathIcon, ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, ChevronUpIcon, MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import Drawer from "../components/Drawer";
import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/solid";

// Table Head
const TABLE_HEAD_INVITES = ["Email", "Name", "Employee ID", "Status"];
const TABLE_HEAD = ["Employee ID", "Employee", "Email", "Working Days", "Joining Date", "Action"];

const Employees = () => {
  // Context
  const { loading, setLoading, openSuccessModal, btnLoading, setBtnLoading, user } = useLoggedContext();

  // React Router Dom Hooks
  const navigate = useNavigate();

  // UseStates

  // Tab & search
  const [activeTab, setActiveTab] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [loadingRows, setLoadingRows] = useState([]);
  const [inviteLoading, setInviteLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("today");
  const [selectedCampus, setSelectedCampus] = useState({ label: "All", value: "All" });
  const [selectedDepartment, setSelectedDepartment] = useState({ label: "All", value: "All" });

  // Data
  const [checkedItems, setCheckedItems] = useState([]);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [employeesListing, setEmployeesListing] = useState([]);
  const [invitedEmployeesListing, setInvitedEmployeesListing] = useState([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [dateError, setDateError] = useState("");

  // Pagination
  const [totalPageInvites, setTotalPageInvites] = useState(0);
  const [totalEmployeesList, setTotalEmployeesList] = useState(0);
  const [totalPageEmployees, setTotalPageEmployees] = useState(0);
  const [currentPageInvites, setCurrentPageInvites] = useState(1);
  const [currentPageEmployees, setCurrentPageEmployees] = useState(1);
  const [totalInvitedEmployeesList, setTotalInvitedEmployeesList] = useState(0);

  // Modal
  const [openInviteUserModal, setOpenInviteUserModal] = useState(false);
  const [openAddEmployeeModal, setOpenAddEmployeeModal] = useState(false);
  const [openNotifyUserModal, setOpenNotifyUserModal] = useState({ open: false, data: null });
  const [openStatusChangeModal, setOpenStatusChangeModal] = useState({ open: false, label: "", id: "", name: "" });
  const [openCancelInviteModal, setOpenCancelInviteModal] = useState({ open: false, label: "", id: "" });
  const [openResendInviteModal, setOpenResendInviteModal] = useState({ open: false, label: "", email: "" });
  const [openUserDrawerModal, setOpenUserDrawerModal] = useState({ open: false, data: null });
  // Active Tab
  const handleTabClick = (index) => {
    setActiveTab(index);
    if (index === 0) {
      const checkboxes = document.querySelectorAll('input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = false;
      });

      setCheckedItems([]);
      setIsCheckedAll(false);
    }
  };

  // employee Table Sorting
  const { items, requestSort, sortConfig } = useSortableData(employeesListing);

  // Invited Employee Table Sorting
  const { items: invitesList, requestSort: invitesListSort, sortConfig: invitesListSortConfig } = useSortableData(invitedEmployeesListing);

  // Media Queries
  const isLaptop = useMediaQuery({ minWidth: 1024 });
  const isLaptopMedium = useMediaQuery({ minWidth: 1536 });
  const isLargeScreenLaptop = useMediaQuery({ minWidth: 1700 });
  const itemsPerPage = isLargeScreenLaptop ? 10 : isLaptopMedium ? 8 : isLaptop ? 8 : 10;

  // Invited Employee Checkbox Functionality

  // Update handleAllCheckboxChange to set isCheckedAll
  const handleAllCheckboxChange = (isChecked) => {
    setCheckedItems(isChecked ? invitesList.map((item) => item?.id) : []);
    setIsCheckedAll(isChecked);
  };

  // Getting th checkbox
  const emailCheckbox = document.querySelector('thead th input[type="checkbox"]');

  // Selected checkboxes
  const handleCheckboxChangeEmployee = (item) => {
    const updatedCheckedItems = isChecked(item) ? checkedItems.filter((checkedItemId) => checkedItemId !== item?.id) : [...checkedItems, item?.id];

    // Update the checked items state
    setCheckedItems(updatedCheckedItems);

    // Update isCheckedAll state based on whether all checkboxes are checked
    setIsCheckedAll(updatedCheckedItems.length === invitesList.length);

    // Check the "Email" checkbox if all other checkboxes are checked
    if (updatedCheckedItems.length === invitesList.length) {
      if (emailCheckbox) {
        emailCheckbox.checked = true;
      }
    } else {
      // Uncheck the "Email" checkbox if any other checkbox is unchecked
      if (emailCheckbox) {
        emailCheckbox.checked = false;
      }
    }
  };

  const isChecked = (item) => checkedItems.includes(item?.id);

  // Resend invite to all
  const handleResendInviteAll = () => {
    setInviteLoading(true);
    ApiServices.employees
      .resendInviteAll()
      .then((res) => {
        const { data, message } = res;

        if (data.code === 200) {
          setTimeout(() => {
            setInviteLoading(false);
            getInvitedEmployees();
            openSuccessModal({
              title: "Success!",
              message: "All Invite has been resent",
              onPress: (close) => {
                close();
                getInvitedEmployees();
              },
            });
          }, 1000);
        }
      })
      .catch((err) => {});
  };

  // Cancel invite to all
  const handleCancelInviteAll = () => {
    setInviteLoading(true);
    ApiServices.employees
      .cancelInviteAll()
      .then((res) => {
        const { data, message } = res;

        if (data.code === 200) {
          setTimeout(() => {
            setInviteLoading(false);
            getInvitedEmployees();
            openSuccessModal({
              title: "Success!",
              message: "All Invite has been Deleted",
              onPress: (close) => {
                close();
                getInvitedEmployees();
              },
            });
          }, 1000);
        }
      })
      .catch((err) => {});
  };

  // Resend invite to selected
  const handleResendInviteSelected = () => {
    setInviteLoading(true);
    const payload = {
      user_ids: checkedItems,
    };

    ApiServices.employees
      .inviteResendSelected(payload)
      .then((res) => {
        const { data, message } = res;

        if (data.code === 200) {
          setCheckedItems([]);
          emailCheckbox.checked = false;

          setTimeout(() => {
            setInviteLoading(false);
            getInvitedEmployees();
            openSuccessModal({
              title: "Success!",
              message: "Invite has been resent to selected employees",
              onPress: (close) => {
                close();
                getInvitedEmployees();
              },
            });
          }, 1000);
        }
      })
      .catch((err) => {});
  };

  // Cancel invite to selected
  const handleCancelInviteSelected = () => {
    setInviteLoading(true);
    const payload = {
      user_ids: checkedItems,
    };

    ApiServices.employees
      .inviteCancelSelected(payload)
      .then((res) => {
        const { data, message } = res;

        if (data.code === 200) {
          setCheckedItems([]);
          emailCheckbox.checked = false;

          setTimeout(() => {
            setInviteLoading(false);
            getInvitedEmployees();
            openSuccessModal({
              title: "Success!",
              message: "Invite has been Deleted to selected employees",
              onPress: (close) => {
                close();
                getInvitedEmployees();
              },
            });
          }, 1000);
        }
      })
      .catch((err) => {});
  };

  // Cancel Invite Single
  const cancelInvite = () => {
    const payload = {
      user_ids: [openCancelInviteModal?.id],
    };

    setBtnLoading(true);

    ApiServices.employees
      .inviteCancelSelected(payload)
      .then((res) => {
        const { data, message } = res;

        if (data.code === 200) {
          setBtnLoading(false);
          getInvitedEmployees();
          setOpenCancelInviteModal({ open: false, label: "", id: "" });

          openSuccessModal({
            title: "Success!",
            message: "Invite has been Deleted",
            onPress: (close) => {
              close();
              getInvitedEmployees();
            },
          });
        }
      })
      .catch((err) => {});
  };

  // Resend Invite Single
  const resendInvite = () => {
    setBtnLoading(true);
    let formData = new FormData();
    formData.append("email", openResendInviteModal?.email);

    ApiServices.employees
      .resendInvite(formData)
      .then((res) => {
        const { data, message } = res;

        if (data.code === 200) {
          setBtnLoading(false);
          getInvitedEmployees();
          setOpenResendInviteModal({ open: false, label: "", email: "" });
          openSuccessModal({
            title: "Success!",
            message: "Invite has been Resent",
            onPress: (close) => {
              close();
              getInvitedEmployees();
            },
          });
        }
      })
      .catch((err) => {});
  };

  // Get Employees List
  const getEmployees = async (sDate, eDate, sCampus, sDepartment) => {
    setLoading(true);
    const payload = {
      search: searchText,
      page: currentPageEmployees,
      records_no: itemsPerPage,
      start_date: sDate !== null ? sDate : sDate,
      end_date: eDate !== null ? eDate : eDate,
      department_id: sDepartment?.value === "All" ? "" : sDepartment?.value,
      campus_id: sCampus?.value === "All" ? "" : sCampus?.value,
    };

    const res = await ApiServices.employees
      .getEmployeeListing(payload)
      .then((res) => {
        const { data, message } = res;
        //
        if (data.code === 200) {
          setLoading(false);
          setEmployeesListing(data?.data?.data);
          setTotalEmployeesList(data?.data?.total);
          setCurrentPageEmployees(data?.data?.current_page);
          setTotalPageEmployees(Math.ceil(data?.data?.total / data?.data?.per_page));
        }
      })
      .catch((err) => {});
  };

  // Get Invited Employees List
  const getInvitedEmployees = async (emptySearch) => {
    setLoading(true);
    const payload = {
      search: emptySearch ? "" : searchText,
      page: currentPageInvites,
      records_no: itemsPerPage,
    };

    const res = await ApiServices.employees
      .getInvitedEmployeeListing(payload)
      .then((res) => {
        const { data, message } = res;

        if (data.code === 200) {
          setLoading(false);
          setInvitedEmployeesListing(data?.data?.data);
          setTotalInvitedEmployeesList(data?.data?.total);
          setCurrentPageInvites(data?.data?.current_page);
          setTotalPageInvites(Math.ceil(data?.data?.total / data?.data?.per_page));
        }
      })
      .catch((err) => {});
  };

  // Active Deactive
  const handleActiveDeactiveEmployee = (item) => {
    setLoadingRows((prevLoadingRows) => [...prevLoadingRows, item.id]);
    // let employeeMessage = openStatusChangeModal?.label === "activate" ? `${openStatusChangeModal.name} has been Activated` : `${openStatusChangeModal.name} has been Deactivated`;
    const formData = new FormData();
    // formData.append("id", openStatusChangeModal?.id);
    formData.append("id", item?.id);
    ApiServices.employees
      .activeDeactiveEmployee(formData)
      .then((res) => {
        const { data, message } = res;
        if (data.code === 200) {
          // setActiveTab(0);
          getEmployees("", false);
          setTimeout(() => {
            setLoadingRows((prevLoadingRows) => prevLoadingRows?.filter((rowId) => rowId !== item.id));
          }, 600);

          // setOpenStatusChangeModal({ open: false, label: "", id: "", name: "" });
          // openSuccessModal({
          //   title: "Success!",
          //   message: employeeMessage,
          //   onPress: (close) => {
          //     close();
          //     setActiveTab(0);
          //     getEmployees();
          //   },
          // });
        }
      })
      .catch((err) => {});
  };

  // Employee page change
  const handleEmployeesPageChange = ({ selected }) => {
    setCurrentPageEmployees(selected + 1);
  };

  // Invited employee page change
  const handleInvitesPageChange = ({ selected }) => {
    setCurrentPageInvites(selected + 1);

    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });

    setCheckedItems([]);
    setIsCheckedAll(false);
  };

  // Handle Search
  const handleSearch = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (searchText.trim() !== "") {
        if (activeTab === 0) {
          await getEmployees();
          setCurrentPageEmployees(1);
        } else {
          await getInvitedEmployees();
          setCurrentPageInvites(1);
        }
      }
    }
  };

  // Use Effects

  useEffect(() => {
    getEmployees();
  }, [currentPageEmployees]);

  useEffect(() => {
    getInvitedEmployees();
  }, [currentPageInvites]);

  // Lottie Option
  const emptyFolderAnimation = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [appliedFiltersCount, setAppliedFiltersCount] = useState(0);

  const getDataByFilter = async (filters) => {
    const filterCount = Object.values(filters).reduce((count, filter) => {
      if (filter !== undefined) {
        if (typeof filter === "object" && filter !== null) {
          const innerCount = Object.values(filter).reduce((innerCount, innerFilter) => (innerFilter !== undefined ? innerCount + 1 : innerCount), 0);
          return count + (innerCount > 0 ? 1 : 0);
        } else {
          return count + 1;
        }
      }
      return count;
    }, 0);

    setAppliedFiltersCount(filterCount);
  };

  const todayEndDate = moment.utc().endOf("day");
  const todayStartDate = moment.utc().startOf("day");

  const [selectedCampusOption, setSelectedCampusOption] = useState(null);
  const [selectedDepartmentOption, setSelectedDepartmentOption] = useState(null);
  const [selectedValue, setSelectedValue] = useState("");
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  const [allCampuses, setAllCampuses] = useState([]);
  const [allDepartments, setAllDepartments] = useState([]);

  const getCampusList = () => {
    ApiServices.campus
      .getCampuses()
      .then((res) => {
        const { data, message } = res;

        if (data.code === 200) {
          let settingsData = data?.data?.data?.map((item) => ({
            label: item.name,
            value: item.id,
          }));

          settingsData = [{ label: "All", value: "All" }, ...settingsData];

          setAllCampuses(settingsData);
        }
      })
      .catch((err) => {});
  };

  const getDepartmentList = (campusValue) => {
    // const campusValue = selectedCampusOption?.value;

    // if (campusValue && campusValue !== "All") {
    let payload = {
      mode: 1,
      campus_id: campusValue,
    };

    ApiServices.department
      .getDepartments(payload)
      .then((res) => {
        const { data, message } = res;
        if (data.code === 200) {
          let settingsData = data?.data?.map((item) => ({
            label: item.name,
            value: item.id,
          }));

          settingsData = [{ label: "All", value: "All" }, ...settingsData];

          setAllDepartments(settingsData);
        }
      })
      .catch((err) => {
        // Handle error here, if necessary
      });
    // }
  };

  useEffect(() => {
    getCampusList();
    getDepartmentList();
  }, []);

  // useEffect(() => {
  //   if (selectedCampusOption?.value && selectedCampusOption?.value !== "All") {
  //     getDepartmentList();
  //   } else {
  //     setAllDepartments([]);
  //     setSelectedDepartmentOption(null);
  //   }
  // }, [selectedCampusOption]);

  useEffect(() => {
    // Trigger the API call when filters are cleared
    if (selectedCampusOption === null && selectedDepartmentOption === null && appliedFiltersCount === 0) {
      getEmployees(dateRange.startDate, dateRange.endDate, selectedCampusOption, selectedDepartmentOption);
    }
  }, [selectedCampusOption, selectedDepartmentOption, appliedFiltersCount]);

  const clearFilter = () => {
    setSelectedFilter("today");
    setSelectedCampus({ label: "All", value: "All" });
    setSelectedDepartment({ label: "All", value: "All" });
    setSelectedCampusOption(null);
    setSelectedDepartmentOption(null);
    setAppliedFiltersCount(0);
    setDateRange({
      startDate: null,
      endDate: null,
    });
    setDateError("");
    setSelectedValue("");
  };

  // "employee-add-edit-delete",
  // "employee-see-user-detail",
  // "employee-mark-as-mental-health-absence",
  // "employee-add-aciton-logs",
  // "employee-view-submitted-forms",

  return (
    <>
      <div className="card h-[82vh] 3xl:h-[84vh]">
        <div className="mb-6">
          <div className="sticky top-0 z-[2] flex w-full flex-col items-start justify-between gap-x-4 bg-white md:flex-row md:items-center xl:gap-x-6">
            <div className="flex gap-x-3 3xl:gap-x-6">
              <h3
                className={`cursor-pointer text-sm font-medium xl:text-base 3xl:text-lg ${
                  activeTab === 0 ? "border-b-2 border-secondary text-secondary" : "text-gray-500"
                }`}
                onClick={() => handleTabClick(0)}
              >
                Employees ({totalEmployeesList})
              </h3>
              <div className="flex items-center gap-x-2">
                <h3
                  className={`cursor-pointer text-base font-medium 3xl:text-lg ${
                    activeTab === 1 ? "border-b-2 border-secondary text-secondary" : "text-gray-500"
                  }`}
                  onClick={() => handleTabClick(1)}
                >
                  Invites ({totalInvitedEmployeesList})
                </h3>
              </div>
              <ArrowPathIcon
                onClick={() => {
                  getEmployees();
                  getInvitedEmployees();
                }}
                className="mt-1 h-5 w-5 cursor-pointer text-grayColor300"
              />
            </div>

            {/* Search */}

            <div className="flex items-center justify-center gap-x-2 md:mt-0 2xl:gap-x-4">
              {(user?.user?.permissions?.includes("employee-invite-users") || user?.user?.role === "setting_user") && (
                <button
                  className="rounded-full bg-[#FF9D86] px-8 py-2.5 text-xs text-white xl:text-sm"
                  type="button"
                  onClick={() => setOpenInviteUserModal(true)}
                >
                  Invite Users
                </button>
              )}
              <button
                className="rounded-full bg-secondary px-8 py-2.5 text-xs text-white xl:text-sm"
                type="button"
                onClick={() => setOpenAddEmployeeModal(true)}
              >
                Add Employees
              </button>

              {activeTab === 0 && (
                <>
                  {appliedFiltersCount > 0 ? (
                    <button
                      type="button"
                      className="font-poppins flex h-9 w-56 items-center justify-start
                   gap-x-3 rounded-full border  border-lightGreenColor px-4 text-base  font-medium  focus:outline-none"
                    >
                      <h2
                        className="shrink-0 text-base font-medium text-lightGreenColor"
                        onClick={() => setOpenUserDrawerModal({ open: true, data: null })}
                      >
                        {appliedFiltersCount} Active Filters
                      </h2>
                      <div className="h-3/4 border-l-2 border-grayColor200"></div>
                      <div className="flex items-center gap-x-1">
                        <img src={images.REDCLOSE} alt="Close" className="h-4 w-4" />
                        <h2 className="text-sm text-red-500" onClick={clearFilter}>
                          Clear
                        </h2>
                      </div>
                    </button>
                  ) : (
                    <button
                      onClick={() => setOpenUserDrawerModal({ open: true, data: null })}
                      type="button"
                      className="font-poppins inline-flex h-9 items-center  justify-start gap-x-5
                rounded-full border border-transparent bg-lightGreenColor py-2.5 px-4 text-xs text-white hover:bg-lightGreenColor/75 focus:outline-none xl:text-sm"
                    >
                      Filter by Campus
                    </button>
                  )}
                </>
              )}

              <div className="relative flex items-center ">
                <div className="pointer-events-none absolute inset-y-0 left-0 z-10 flex items-center pl-4">
                  <MagnifyingGlassIcon className="h-5 w-5 text-primary-color-100" />
                </div>

                <input
                  type="search"
                  id="search"
                  name="search"
                  placeholder="Search..."
                  autocomplete="off"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                    if (e.target.value.trim() === "") {
                      activeTab === 0 ? getEmployees() : getInvitedEmployees();
                    }
                  }}
                  onKeyDown={handleSearch}
                  className="focus:ring-primary-color block w-full rounded-full border border-grayColor200 px-4 py-2 pl-11 text-sm text-primary-color-100 focus:border-primary-color-100"
                />
              </div>

              {/* {activeTab === 0 && <StatusFilter selected={statusOption} setSelected={setStatusOption} options={options} />} */}
            </div>
          </div>
        </div>

        {activeTab === 0 ? (
          <>
            <div className="-mx-6 mb-8 overflow-x-auto">
              <table className="w-full text-left">
                <thead>
                  <tr>
                    {TABLE_HEAD.map((head) => (
                      <th
                        key={head}
                        className="w-38 border-b border-gray-100 bg-white p-4 first:pl-6"
                        onClick={() => {
                          let sortKey;
                          if (head === "Employee ID") {
                            sortKey = "employee.id";
                          } else if (head === "Employee") {
                            sortKey = "employee.name";
                          } else if (head === "Working Days") {
                            sortKey = "employee.shift_time";
                          } else if (head === "Joining Date") {
                            sortKey = "employee.joining_date";
                          } else {
                            sortKey = head.toLowerCase();
                          }
                          requestSort(sortKey);
                        }}
                      >
                        <p className="cursor-pointer whitespace-nowrap font-inter text-xs font-semibold leading-5 3xl:text-sm">
                          {head}
                          {sortConfig.key ===
                            (head === "Employee ID"
                              ? "employee.id"
                              : head === "Employee"
                                ? "employee.name"
                                : head === "Working Days"
                                  ? "employee.shift_time"
                                  : head === "Joining Date"
                                    ? "employee.joining_date"
                                    : head.toLowerCase()) && sortConfig.direction === "asc" ? (
                            <ChevronUpIcon className="inline-block h-4 w-3.5" />
                          ) : (
                            <ChevronDownIcon className="inline-block h-4 w-3.5" />
                          )}
                        </p>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="7">
                        <Skeleton count={itemsPerPage} height={50} />
                      </td>
                    </tr>
                  ) : items.length > 0 ? (
                    items.map((item, index) => (
                      <tr
                        key={item?.id}
                        className="cursor-pointer even:bg-gray-50 hover:bg-gray-50"
                        onClick={() =>
                          navigate(item?.id ? `${item?.id}` : "1", {
                            state: { employeeDetail: item },
                          })
                        }
                      >
                        <td className="py-3 pl-6 pr-4">
                          <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item?.employee[0]?.emp_id || "N/A"}</p>
                        </td>
                        <td className="py-3 pl-4 3xl:px-4">
                          <div className="flex items-center">
                            <div className="mr-3 h-8 w-8 rounded-full">
                              <img
                                className="mr-3 h-8 w-8 rounded-full"
                                src={
                                  item?.employee[0]?.profile_image_url ? employeeMediaUrl + item?.employee[0]?.profile_image_url : images.placeholder
                                }
                                alt="Profile"
                              />
                            </div>
                            <p className="cursor-pointer truncate text-xs font-normal text-primary-color-200 hover:text-secondary 3xl:text-sm">
                              {item?.employee[0]?.name || "N/A"}
                            </p>
                          </div>
                        </td>

                        <td className="py-3 pl-4 pr-3 3xl:px-4">
                          <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item?.email || "N/A"}</p>
                        </td>
                        <td className="py-3 pl-4 pr-3 3xl:px-4">
                          <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item?.employee[0]?.shift_time || "N/A"}</p>
                        </td>
                        <td className="py-3 pl-2 3xl:px-4">
                          <p className="text-xs font-normal text-primary-color-200 3xl:text-sm ">
                            {item?.employee[0]?.unix_joining_date !== null
                              ? moment.unix(item?.employee[0]?.unix_joining_date).format("D MMM YYYY")
                              : "N/A"}
                          </p>
                        </td>
                        <td className="w-44 py-3 pl-4 pr-3 3xl:px-4">
                          <div className="flex items-center gap-x-2 3xl:gap-x-3">
                            {(user?.user?.permissions?.includes("employee-notify-user") || user?.user?.role === "setting_user") && (
                              <span
                                onClick={() => setOpenNotifyUserModal({ open: true, data: item })}
                                className="cursor-pointer text-xs font-normal text-secondary underline underline-offset-4 3xl:text-sm"
                              >
                                Notify User
                              </span>
                            )}
                            <span
                              // onClick={() => {
                              //   setOpenStatusChangeModal({ open: true, label: item?.status === 0 ? "activate" : "deactivate", id: item?.id, name: item?.employee[0]?.name });
                              // }}
                              onClick={() => handleActiveDeactiveEmployee(item)}
                              className={`cursor-pointer text-xs font-normal ${item?.status === 0 ? "text-accent" : " text-red-500"} underline underline-offset-4 3xl:text-sm`}
                            >
                              {loadingRows.includes(item.id) ? <Spinner /> : item.status === 0 ? "Unblock" : "Block"}
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    // Render "No Data" message
                    <tr className="h-[500px]">
                      <td colSpan="6">
                        <Lottie options={emptyFolderAnimation} width={200} height={200} />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div className="absolute bottom-4">
              <ReactPaginate
                breakLabel="..."
                pageRangeDisplayed={5}
                marginPagesDisplayed={2}
                activeClassName="active"
                nextClassName="item next"
                renderOnZeroPageCount={null}
                breakClassName="item break-me "
                containerClassName="pagination"
                previousClassName="item previous"
                pageCount={totalPageEmployees}
                pageClassName="item pagination-page"
                forcePage={currentPageEmployees - 1}
                onPageChange={handleEmployeesPageChange}
                nextLabel={<ChevronRightIcon className="h-5 w-5" />}
                previousLabel={<ChevronLeftIcon className="h-5 w-5" />}
              />
            </div>
          </>
        ) : (
          <>
            <div className="-mx-6 mb-8 overflow-x-auto">
              <table className="w-full text-left">
                <thead>
                  <tr>
                    {TABLE_HEAD_INVITES.map((head) => (
                      <th
                        key={head}
                        className="border-b border-gray-100 bg-white p-4 first:pl-6"
                        onClick={() => {
                          if (head !== "Email") {
                            let sortKey;
                            if (head === "Name") {
                              sortKey = "employee.name";
                            } else if (head === "Employee ID") {
                              sortKey = "employee.name";
                            } else if (head === "Created at") {
                              sortKey = "created_at";
                            } else {
                              sortKey = head.toLowerCase();
                            }
                            invitesListSort(sortKey);
                          }
                        }}
                      >
                        {head === "Email" ? (
                          <div className="flex items-center">
                            <label className="flex items-center">
                              <input type="checkbox" onChange={(e) => handleAllCheckboxChange(e.target.checked)} className="mr-2 rounded" />
                              <p className="cursor-pointer whitespace-nowrap font-inter text-xs font-semibold leading-5 3xl:text-sm">{head}</p>
                            </label>
                            <p
                              className="inline-block h-7 cursor-pointer"
                              onClick={() => {
                                let sortKey = head.toLowerCase();
                                invitesListSort(sortKey);
                              }}
                            >
                              {invitesListSortConfig.key === head.toLowerCase() && invitesListSortConfig.direction === "asc" ? (
                                <ChevronUpIcon className="inline-block h-4 w-3.5" />
                              ) : (
                                <ChevronDownIcon className="inline-block h-4 w-3.5" />
                              )}
                            </p>
                          </div>
                        ) : (
                          <p className="cursor-pointer whitespace-nowrap font-inter text-xs font-semibold leading-5 3xl:text-sm">
                            {head}
                            {invitesListSortConfig.key ===
                              (head === "Name"
                                ? "employee.name"
                                : head === "Employee ID"
                                  ? "employee.id"
                                  : head === "Created at"
                                    ? "created_at"
                                    : head.toLowerCase()) && invitesListSortConfig.direction === "asc" ? (
                              <ChevronUpIcon className="inline-block h-4 w-3.5" />
                            ) : (
                              <ChevronDownIcon className="inline-block h-4 w-3.5" />
                            )}
                          </p>
                        )}
                      </th>
                    ))}

                    {/* Action TH */}
                    <th className="border-b border-gray-100 bg-white p-4 first:pl-6">
                      <Menu as="div" className="relative inline-block text-left">
                        <div>
                          <Menu.Button className="cursor-pointer whitespace-nowrap font-inter text-xs font-semibold leading-5 3xl:text-sm">
                            Action <ChevronDownIcon className="inline-block h-4 w-3.5" aria-hidden="true" />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            className={`absolute right-0 mt-2 ${isCheckedAll || checkedItems.length > 0 ? "w-60" : "w-40"} origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none`}
                          >
                            <div className="px-1 py-1">
                              {checkedItems.length === 0 && (
                                <>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <button
                                        onClick={handleResendInviteAll}
                                        className={`${
                                          active ? "bg-secondary text-white" : "text-primary-color-100"
                                        } group flex w-full items-center rounded-md px-2 py-2 text-xs font-normal`}
                                      >
                                        Resend invite to all
                                      </button>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <button
                                        onClick={handleCancelInviteAll}
                                        className={`${
                                          active ? "bg-red-500 text-white" : "text-red-500"
                                        } group flex w-full items-center rounded-md px-2 py-2 text-xs font-normal`}
                                      >
                                        Delete all Invites
                                      </button>
                                    )}
                                  </Menu.Item>
                                </>
                              )}
                              {checkedItems.length > 0 && (
                                <>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <button
                                        onClick={handleResendInviteSelected}
                                        className={`${
                                          active ? "bg-secondary text-white" : "text-primary-color-100"
                                        } group flex w-full items-center rounded-md px-2 py-2 text-xs font-normal`}
                                      >
                                        Resend invite to selected ({checkedItems.length}) invite
                                      </button>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <button
                                        onClick={handleCancelInviteSelected}
                                        className={`${
                                          active ? "bg-red-500 text-white" : "text-red-500"
                                        } group flex w-full items-center rounded-md px-2 py-2 text-xs font-normal`}
                                      >
                                        Delete selected ({checkedItems.length}) invite
                                      </button>
                                    )}
                                  </Menu.Item>
                                </>
                              )}
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="6">
                        <Skeleton count={itemsPerPage} height={50} />
                      </td>
                    </tr>
                  ) : invitesList.length > 0 ? (
                    invitesList.map((item, index) => (
                      <tr key={item?.id} className="even:bg-gray-50">
                        <td className="py-4 pl-6 pr-4">
                          <label className="flex items-center">
                            <input
                              checked={isChecked(item)}
                              onChange={() => handleCheckboxChangeEmployee(item)}
                              type="checkbox"
                              value={item?.email}
                              className="mr-2 rounded"
                            />
                            <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item?.email}</p>
                          </label>
                        </td>
                        <td className="py-4 pl-4 3xl:px-4">
                          <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item?.employee[0]?.name || "N/A"}</p>
                        </td>
                        <td className="py-4 pl-4 pr-3 3xl:px-4">
                          <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item?.employee[0]?.emp_id || "00"}</p>
                        </td>

                        <td className="py-4 pl-4 pr-3 3xl:px-4">
                          <Badge status={item?.employee[0]?.status} />
                        </td>

                        <td className="w-52 py-4 pl-4 pr-3 3xl:w-64 3xl:px-4">
                          <div className="flex items-center gap-x-3">
                            <span
                              onClick={() => setOpenResendInviteModal({ open: true, label: "resend this invite", email: item?.email })}
                              className="cursor-pointer text-xs font-normal text-accent underline 3xl:text-sm"
                            >
                              Resend Invite
                            </span>
                            <span
                              onClick={() => setOpenCancelInviteModal({ open: true, label: "Delete this invite", id: item?.id })}
                              className="cursor-pointer text-xs font-normal text-red-500 underline 3xl:text-sm"
                            >
                              Delete Invite
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    // Render "No Data" message
                    <tr className=" h-[500px]">
                      <td colSpan="6">
                        <Lottie options={emptyFolderAnimation} width={200} height={200} />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div className="absolute bottom-4">
              <ReactPaginate
                breakLabel="..."
                pageRangeDisplayed={5}
                marginPagesDisplayed={2}
                activeClassName="active"
                nextClassName="item next"
                renderOnZeroPageCount={null}
                breakClassName="item break-me "
                containerClassName="pagination"
                previousClassName="item previous"
                pageClassName="item pagination-page "
                pageCount={totalPageInvites}
                forcePage={currentPageInvites - 1}
                onPageChange={handleInvitesPageChange}
                nextLabel={<ChevronRightIcon className="h-5 w-5" />}
                previousLabel={<ChevronLeftIcon className="h-5 w-5" />}
              />
            </div>
          </>
        )}
      </div>

      {inviteLoading && (
        <div className="absolute top-0 left-0 z-50 flex h-screen w-full items-center justify-center bg-black/50">
          <Spinner />
        </div>
      )}

      {/* Add Employee Modal */}
      <AddManuallyModal isOpen={openAddEmployeeModal} setIsOpen={setOpenAddEmployeeModal} onSuccess={getEmployees} />

      {/* Invite Employee */}
      <InviteUsersModal isOpen={openInviteUserModal} setIsOpen={setOpenInviteUserModal} onSuccess={getEmployees} />

      {/* Notify User Modal */}
      <NotifyUserModal
        data={openNotifyUserModal.data}
        isOpen={openNotifyUserModal.open}
        setIsOpen={(open) => setOpenNotifyUserModal((prev) => ({ ...prev, open }))}
      />

      {/* Block Modal */}
      <ConfirmationModal
        isOpen={openStatusChangeModal.open}
        label={openStatusChangeModal.label}
        handleSubmit={handleActiveDeactiveEmployee}
        setIsOpen={(open) => setOpenStatusChangeModal((prev) => ({ ...prev, open }))}
      />

      {/* Resend Invite Modal */}
      <ConfirmationModal
        handleSubmit={resendInvite}
        isOpen={openResendInviteModal.open}
        label={openResendInviteModal.label}
        setIsOpen={(open) => setOpenResendInviteModal((prev) => ({ ...prev, open }))}
      />

      {/* Cancel Invite Modal */}
      <ConfirmationModal
        handleSubmit={cancelInvite}
        isOpen={openCancelInviteModal.open}
        label={openCancelInviteModal.label}
        setIsOpen={(open) => setOpenCancelInviteModal((prev) => ({ ...prev, open }))}
      />

      <Drawer
        isOpen={openUserDrawerModal.open}
        setIsOpen={(open) => setOpenUserDrawerModal((prev) => ({ ...prev, open }))}
        onApplyFilters={getDataByFilter}
        allCampuses={allCampuses}
        allDepartments={allDepartments}
        setDateRange={setDateRange}
        dateRange={dateRange}
        setSelectedDepartmentOption={setSelectedDepartmentOption}
        selectedDepartmentOption={selectedDepartmentOption}
        setSelectedCampusOption={setSelectedCampusOption}
        selectedCampusOption={selectedCampusOption}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        api={getEmployees}
        clearFilter={clearFilter}
        setIsFilterApplied={setIsFilterApplied}
        isFilterApplied={isFilterApplied}
        setDateError={setDateError}
        dateError={dateError}
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
        selectedCampus={selectedCampus}
        setSelectedCampus={setSelectedCampus}
        selectedDepartment={selectedDepartment}
        setSelectedDepartment={setSelectedDepartment}
        setAllDepartments={setAllDepartments}
        getDepartmentList={getDepartmentList}
      />
    </>
  );
};

export default Employees;
